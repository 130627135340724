<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="px-1"
          order="2"
          order-md="1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableQRPh.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Paid From"
            label-for="filter_date_from"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_date_from"
              v-model="tableQRPh.filter.date_from"
              size="sm"
              type="date"
              :max="tableQRPh.filter.date_to"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="px-1"
          order="1"
          order-md="2"
        >
          <b-form-group
            label="Paid To"
            label-for="filter_date_to"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_date_to"
              v-model="tableQRPh.filter.date_to"
              size="sm"
              type="date"
              :min="tableQRPh.filter.date_from"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="tableQRPh.filter.date_to && tableQRPh.filter.date_from"
          cols="12"
          class="px-1 mb-2"
          order="3"
        >
          <b-overlay
            rounded
            opacity="0.1"
            spinner-small
            class="d-inline"
            spinner-variant="warning"
            :show="exporter.busy"
          >
            <json-excel
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="btn btn-success btn-sm"
              :fetch="excelQRPhProvider"
              :before-generate="onStartExcelExport"
              :before-finish="onFinishExcelExport"
              :fields="exporter.excel.fields"
              :escape-csv="exporter.excel.escape"
              :type="exporter.excel.type"
              :header="exporter.excel.header"
              :footer="exporter.excel.footer"
              :name="excelFileName"
              worksheet="QR Ph"
            >
              {{ exporter.busy ? 'Exporting' : 'Export As Excel' }}
            </json-excel>
          </b-overlay>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableQRPh"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableQRPh.perPage"
            :current-page="tableQRPh.currentPage"
            :items="tableQRPhProvider"
            :fields="tableQRPh.fields"
            :sort-by.sync="tableQRPh.sortBy"
            :sort-desc.sync="tableQRPh.sortDesc"
            :sort-direction="tableQRPh.sortDirection"
            :filter="tableQRPh.filter"
            :filter-included-fields="tableQRPh.filterOn"
            :busy="tableQRPh.busy"
          >
            <template #cell(index)="row">
              {{ tableQRPh.currentPage * tableQRPh.perPage - tableQRPh.perPage + (row.index + 1) }}
            </template>

            <template #cell()="row">
              <div class="text-wrap text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(amount)="row">
              <div
                class="text-md-nowrap text-right"
                style="min-width: 100px !important;"
              >
                {{ row.value }}
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableQRPh.perPage"
            :options="tableQRPh.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableQRPh.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableQRPh.totalRows"
            :per-page="tableQRPh.perPage"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
// import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { SMerchantReport, SMerchantExport } from '@/services'
import formatter from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import misc from '@/mixins/misc'
import createDate from 'moment'

export default {
  name: 'MerchantReportsQRPh',

  middleware: ['auth', 'merchant'],

  directives: {
    Ripple
  },

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'QRPh'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      tableQRPh: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          date_from: createDate().format('YYYY-MM-DD'),
          date_to: createDate().format('YYYY-MM-DD')
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-200px', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'partner_reference', class: 'min-w-350px' },
          { mobile: 3, key: 'provider_reference', class: 'min-w-350px' },
          { mobile: 4, key: 'amount', class: 'min-w-150px', formatter: this.numberFormatter },
          { mobile: 5, key: 'status', class: 'text-center min-w-100px' },
          { mobile: 6, key: 'paid_at', class: 'min-w-200px', formatter: this.dateTimeFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      exporter: {
        busy: false,
        pdf: null,
        excel: {
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            '#': {
              field: 'page_number'
            },
            'Created At': {
              field: 'created_at',
              callback: createdAt => this.dateTimeFormatter(createdAt)
            },
            'Partner Reference': {
              field: 'partner_reference'
            },
            'Provider Reference': {
              field: 'provider_reference'
            },
            Amount: {
              field: 'amount',
              callback: amount => this.numberFormatter(amount) || 0.00
            },
            Status: {
              field: 'status'
            },
            'Paid At': {
              field: 'paid_at',
              callback: paidAt => this.dateTimeFormatter(paidAt)
            }
          },
          footer: []
        }
      }
    }
  },

  computed: {
    excelFileName () {
      const dateFrom = this.dateShortFormatter(this.tableQRPh.filter.date_from)
      const dateTo = this.dateShortFormatter(this.tableQRPh.filter.date_to)

      return `QR Ph ( ${dateFrom} to ${dateTo} )`
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableQRPhProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableQRPh.busy = true
      return await SMerchantReport.getQRPh(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_date_from: filter.date_from,
          filter_date_to: filter.date_to
        })
      ).then(({ data }) => {
        this.tableQRPh.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableQRPh.busy = false
      })
    },

    async excelQRPhProvider () {
      if (this.exporter.busy) {
        return null
      }
      this.exporter.busy = true
      return await SMerchantExport.getQRPh(
        this.objectToUrl({
          filter_text: this.tableQRPh.filter.search,
          filter_date_from: this.tableQRPh.filter.date_from,
          filter_date_to: this.tableQRPh.filter.date_to
        })
      ).then(({ data }) => {
        if (data.items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported. Try changing the date range.',
            'Notice'
          )
        }
        return data.items.map((item, i) => {
          item.page_number = Number(i) + 1
          return item
        })
      }).catch(() => []).finally(() => {
        this.exporter.busy = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy) {
        return null
      }

      const dateFrom = this.dateShortFormatter(this.tableQRPh.filter.date_from)
      const dateTo = this.dateShortFormatter(this.tableQRPh.filter.date_to)

      this.exporter.excel.header = [
        'QR Ph',
        `${dateFrom} to ${dateTo}`,
        '&nbsp;'
      ]
    },

    onFinishExcelExport () {
    }
  }
}
</script>
